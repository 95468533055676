<template>
  <div
    :class="className"
    :style="{height:height,width:width}"
  />
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons') // echarts theme
// import { debounce } from '@/utils'
// import geoJson from '../xingtai.json'
import geoJson from '../china.json'
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    chartData: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }

  },
  mounted () {
    this.initChart()
    // this.__resizeHandler = debounce(() => {
    //   if (this.chart) {
    //     this.chart.resize()
    //   }
    // }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart (val) {

      echarts.registerMap('中国', geoJson)
      this.chart = echarts.init(this.$el)
      this.setOptions(this.chartData)
    },
    setOptions ({ expectedData } = {}) {
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>{c}个'
          //  function (params) {
          //   let b1 = ''
          //   let b2 = ''
          //   console.log(params, '11111')
          //   // params.forEach((item) => {
          //   //   // a1 = thas.chartData.totalAmountData[item.dataIndex]
          //   //   b1 = thas.chartData[item.dataIndex].deviceNum
          //   //   b2 = thas.chartData[item.dataIndex].siteNum
          //   // })
          //   // return '设备数:' + b1 + '<br />' + '站点数:' + b2
          // }
          // 
        },

        series: [
          {
            name: '中国',
            type: 'map',
            map: '中国',
            label: {
              show: true,
              color: '#fff'
            },
            itemStyle: {
              normal: {
                show: true,
                areaColor: '#337ab7',
                borderColor: 'rgb(240, 255, 255)',
                borderWidth: '1',
              },

            },
            emphasis: {
              itemStyle: {
                areaColor: '#00FF00'
              },
              label: {
                color: '#fff'
              },
            },
            data: this.chartData.list
            // [
            //   {
            //     name: '信都区', value: 20057.34,
            //     itemStyle: {
            //       normal: {
            //         show: true,
            //         areaColor: '#ffaa00',
            //         borderColor: 'rgb(185, 220, 227)',
            //         borderWidth: '1',
            //       },
            //     },
            //   },
            //   { name: '内丘县', value: 15477.48 },
            //   { name: '临城县', value: 31686.1 },
            //   { name: '柏乡县', value: 6992.6 },
            //   { name: '宁晋县', value: 44045.49 },
            //   { name: '新河县', value: 40689.64 },
            //   { name: '南宫市', value: 37659.78 },
            //   { name: '清河县', value: 45180.97 },
            //   { name: '临西县', value: 55204.26 },
            //   { name: '威县', value: 21900.9 },
            //   { name: '广宗县', value: 4918.26 },
            //   { name: '巨鹿县', value: 5881.84 },
            //   { name: '平乡县', value: 4178.01 },
            //   { name: '隆饶县', value: 2227.92 },
            //   { name: '任泽区', value: 2180.98 },
            //   { name: '南和区', value: 9172.94 },
            //   { name: '襄都区', value: 3368 },
            //   { name: '沙河市', value: 806.98 }
            // ],

          }
        ]
      })
    }
  }
}
</script>
